export default [
    {
      header: 'Collaboration',
      action: "read",
      resource: "social",
      children: [
        {
          title: 'Tâches',
          route: 'collaboration-todo',
          icon: 'CheckSquareIcon',
          action: "read",
          resource: "social"
        },  
        {
          title: 'Messages',
          route: 'collaboration-email',
          icon: 'MailIcon',
          action: "read",
          resource: "social"    
        },
        {
          title: 'Contacts',
          icon: 'UserIcon',
          route: 'collaboration-users-list',
          action: "read",
          resource: "social"    
        },
      ]

    },

]