export default [
  {
    header: 'Administration',
    action: "read",
    resource: "social",
    children: [
      {
        title: 'Utilisateurs',
        icon: 'UsersIcon',
      }, 
      {
        title: 'Workflow / Rôles',
        icon: 'SlidersIcon',
      }, 
      {
        title: 'Reporting',
        icon: 'ColumnsIcon',
      },  
      {
        title: 'API / Connecteurs',
        icon: 'LinkIcon',
      },     
      {
        title: 'Référentiels',
        icon: 'DatabaseIcon',
        children: [
          {
            title: 'Tiers partie',
          }, 
        
          {
            title: 'Type d\'inspection',
            route: 'apps-inspection-configtype',
          },
        ]  
      },  
    ]

  },
 

]