export default [
  {
    header: 'Gestion patrimoniale',
    icon: 'MonitorIcon',
    action: "read",
    resource: "patrimoine",     
    children: [
      {
        title: 'Patrimoine',
        icon: 'GlobeIcon',
        route: 'apps-patrimoine-carte',
        action: "read",
        resource: "patrimoine" 
      },
      {
        title: 'Gestion',
        icon: 'MonitorIcon',
        action: "read",
        resource: "gestion",     
        children: [
          {
            title: 'Plan pluriannuel',
            route: 'apps-gestion-pluriannuel',
            action: "read",
            resource: "gestion" 
          },
          {
            title: 'Budget 2022',
            route: 'apps-gestion-budget',
            action: "read",
            resource: "gestion" 
          },
          {
            title: 'Budget 2023',
            action: "read",
            resource: "gestion" 
          }, 
            
        ],            
      },
      {
        title: 'Inspection',
        icon: 'EyeIcon',
        route: 'apps-inspection',
        action: "read",
        resource: "patrimoine" 
      },
    
      {
        title: 'Désordres',
        icon: 'XOctagonIcon',
        route: 'apps-maintenance-desordre',   
        action: "read",
        resource: "patrimoine"   
      },  
    
      {
        title: 'Maintenance',
        icon: 'ToolIcon',
        route: 'apps-maintenance-list',
        action: "read",
        resource: "patrimoine"   
      },   
    
    
    
    
      {
        title: 'Incidents',
        icon: 'AlertTriangleIcon',
        tag: '3',
        route: 'apps-incident-incident',  
        action: "read",
        resource: "patrimoine" 
      },
    ],  
  },  

   


 
]
